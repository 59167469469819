import React from "react"
import { Link } from "gatsby"
import { getMonthName } from "../../utils"

const ProjectBlocks = ({ post }) => {
	let dateObject = new Date(post.date);
	let formattedDate = getMonthName(dateObject.getMonth()) + ' ' + dateObject.getDate() + ', ' + dateObject.getFullYear();
	console.log(post);
	return (
		<div key={post.id} className="blog__block">
			<Link to={`/blog/` + post.uri} className="blog__link">
				<h2 dangerouslySetInnerHTML={{ __html: post.title }}></h2>
			</Link>
			<p>
				<time><i>{formattedDate + ` - `}</i></time>{post.excerpt}
			</p>
			<Link to={`/blog/` + post.uri} className="blog__cta btn btn--focus-hvr">
				Read More...
			</Link>
		</div>
	)
}

export default ProjectBlocks;