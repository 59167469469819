import React from "react"
import PostBlock from "./postBlock"

const PostBlocks = ({ posts }) => {
	return (
		<div className="blog__wrap constrained is-narrow">
			{posts.nodes.map(post => (
				<PostBlock key={post.id} post={post} />
			))}
		</div>
	)
}

export default PostBlocks;