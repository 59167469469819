import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import PostPagination from "../components/postPagination"
import PageBanner from "../components/banners/pageBanner"
import PostBlocks from "../components/archives/postBlocks"

const Posts = props => {
	const {
		data: {
			wpgraphql: { posts }
		},
		pageContext: { pageNumber, hasNextPage }
	} = props;

	//const currentPage = ( pageNumber ) ? `- Page ${pageNumber}` : ``;

	return (
		<Layout>
			<div>
				<PageBanner 
					title={`Blog`}
				/>
				<section className="blog light content">
					<PostBlocks posts={posts} />
					<PostPagination pageNumber={pageNumber} hasNextPage={hasNextPage} />
				</section>
			</div>
		</Layout>
	)
}

export default Posts;

export const pageQuery = graphql`
	query GET_POSTS($ids: [ID]) {
		wpgraphql {
			posts(where: { in: $ids }) {
				nodes {
					id
					title(format: RENDERED)
					uri	
					date
					excerpt(format: RENDERED)
				}
			}
		}
	}
`